import React from 'react';

/**
 * Пропсы использующиеся для отображения слайда в мобильном меню.
 */
export interface ISlideProps {
    /**
     * Заголовок слайда.
     */
    title: React.ReactNode;
    /**
     * Список пунктов меню слайда.
     */
    children: React.ReactNode;
}

export interface IResponsiveMenuContext {
    /**
     * Открывает слайд с вложенными пунктами меню.
     *
     * Используется только для мобильного меню.
     * @param props Свойства слайда, которые должны быть открыты.
     */
    slideTo(props: ISlideProps);
    /**
     * Возвращается в главное меню.
     *
     * Используется только для мобильного меню.
     */
    backToMenu();
    /**
     * Метод для закрытия бургерного меню.
     */
    closeBurgerMenu();
}

export interface IResponsiveMenuButtonContext {
    /**
     * Порядковый номер текущего пункта меню.
     */
    index: number;
    /**
     * Скрыта ли кнопка из основного меню, когда нет свободного пространства для ее отображения.
     */
    hidden: boolean;
    /**
     * Место отображения пункта меню.
     */
    placement: 'main'|'menu'|'sub-menu';
}

export const ResponsiveMenuContext = React.createContext<IResponsiveMenuContext>(null);

export function useResponsiveMenuContext() {
    return React.useContext(ResponsiveMenuContext);
}

export const ResponsiveMenuButtonContext = React.createContext<IResponsiveMenuButtonContext>({
    index: 0,
    hidden: false,
    placement: 'main',
});

export function useResponsiveMenuButtonContext(): IResponsiveMenuButtonContext {
    return React.useContext(ResponsiveMenuButtonContext);
}