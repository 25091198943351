import {
    accountBorrowerUsersHandler,
    accountInvestorUsersHandler,
    accountPartnerUsersHandler,
    userSidePanelHandler,
} from '@frontend/jetlend-core/src/features/CabinetUserSidePanel/ducks';
import useModalHandlerV2 from '@frontend/jetlend-core/src/hooks/useModalHandlerV2';
import React, {
    useCallback,
    useMemo,
} from 'react';
import useSimpleApiHandler from '@frontend/jetlend-core/src/hooks/useSimpleApiHandler';
import {
    logout,
    redirectToExternal,
} from '@frontend/jetlend-core/src/ducks/router';
import iconPlusPrimary from '@frontend/jetlend-assets/icons/icon-plus--primary.svg';
import {
    ClientType,
    IInvestorPortfolioApiModel,
    InvestorUserType,
} from '@frontend/jetlend-core/src/models/account';
import {
    getAccountCreateEndpoint,
    getAccountSwitchEndpoint,
} from '@frontend/jetlend-core/src/features/CabinetUserSidePanel/services';
import { useDispatch } from 'react-redux';
import UserAccountsGroup, { IProps as UserAccountsGroupProps } from '@ui/components/UserSidePanel/UserAccountsGroup/UserAccountsGroup';
import UserSidePanel, { IProps as UserSidePanelProps } from '@ui/components/UserSidePanel/UserSidePanel';
import UsersSection from '@ui/components/UserSidePanel/UsersSection/UsersSection';
import DropdownActions from '@ui/components/DropdownActions/DropdownActions';
import Button from '@ui/ui/inputs/Button/Button';
import LoaderBlock from '@ui/ui/loaders/LoaderBlock';
import {
    isInvestorHasAnyActivePortfolio,
    reorderUserGroups,
} from './CabinetUserSidePanel.logic';
import useHideJivoChat from '@ui/hooks/useHideJivoChat';
import InvestorUserAccount from './InvestorUserAccount';

export interface IProps extends Pick<UserSidePanelProps, 'header'> {
    /**
     * Текущий тип клиента.
     */
    clientType?: ClientType;
    /**
     * Идентификатор текущего выбранного аккаунта пользователя.
     */
    currentAccountId?: number;
    /**
     * Флаг, разрешающий навигацию по текущему выбранному аккаунту.
     * Если указан как `false` то клик на выбранный счет (`currentAccountId`) не вызовет события `onAccountClick`.
     * @default false
     */
    allowCurrentAccountClick?: boolean;
    /**
     * @event
     * Событие, которое срабатывает, когда какой либо счет был выбран.
     * @param clientType Тип выбранного счета.
     * @param id Уникальный идентификатор выбранного счета.
     */
    onAccountClick?: (clientType: ClientType, id: number) => void;
    /**
     * Компонент реализующий отображение счетов инвестора.
     */
    InvestorUserAccountsGroupComponent?: React.ComponentType<UserAccountsGroupProps<IInvestorPortfolioApiModel>>;
}

/**
 * Компонент реализующий общую логику отображения боковой панели пользователя в кабинете.
 *
 * Внимание! Данный компонент использует ducks логику, ее необходимо зарегистрировать внутри проекта.
 *
 * @see {@link userSidePanelHandler}
 *
 * @example
 * // Для открытия боковой панели, необходимо использовать хендлер `userSidePanelHandler`
 *
 * const openUserSidePanel = useActions(userSidePanelHandler.open);
 */
export default function CabinetUserSidePanel({
    clientType,
    currentAccountId,
    header,
    allowCurrentAccountClick,
    onAccountClick,
    InvestorUserAccountsGroupComponent = UserAccountsGroup,
}: IProps) {
    const [ isOpen, close ] = useModalHandlerV2(userSidePanelHandler);
    useHideJivoChat(isOpen);

    const [ investors, isInvestorsFetching ] = useSimpleApiHandler(accountInvestorUsersHandler, { fetchWhen: isOpen });
    const [ borrowers, isBorrowersFetching ] = useSimpleApiHandler(accountBorrowerUsersHandler, { fetchWhen: isOpen });
    const [ partners, isPartnersFetching ] = useSimpleApiHandler(accountPartnerUsersHandler, { fetchWhen: isOpen });

    const hasIndividualInvestorUser = useMemo(() => investors?.some(investor => investor.type === InvestorUserType.Individual) ?? false, [ investors ]);

    const dispatch = useDispatch();

    const didLogoutClicked = useCallback(() => {
        dispatch(logout());
    }, [ dispatch ]);

    // Показываем загрузчик, только если ни один счет не был еще загружен
    const isFetching = (!investors && !borrowers && !partners) &&
        (isInvestorsFetching || isBorrowersFetching || isPartnersFetching);

    const didAccountClicked = useCallback((accountType: ClientType, accountId: number) => {
        if (!allowCurrentAccountClick) {
            // Если запрещен клик по текущему аккаунту, то пропускаем дальнейший обработчик события
            if (clientType === accountType && currentAccountId === accountId) {
                return;
            }
        }

        // Если кастомный обработчик переключение аккаунтов не указан, то переключаемся стандартным образом через legacy роуты
        if (!onAccountClick) {
            const targetEndpoint = getAccountSwitchEndpoint(accountType, accountId);
            dispatch(redirectToExternal(targetEndpoint));

            return;
        }

        onAccountClick(accountType, accountId);
    }, [ onAccountClick, dispatch, allowCurrentAccountClick, clientType, currentAccountId ]);

    const didInvestorAccountClicked = useCallback((accountId: number) => didAccountClicked(ClientType.Investor, accountId), [ didAccountClicked ]);
    const didBorrowerAccountClicked = useCallback((accountId: number) => didAccountClicked(ClientType.Borrower, accountId), [ didAccountClicked ]);
    const didPartnerAccountClicked = useCallback((accountId: number) => didAccountClicked(ClientType.Partner, accountId), [ didAccountClicked ]);

    const userGroupsByClientType: Record<ClientType, React.ReactNode> = {
        [ClientType.Investor]: investors && investors.length > 0 && (
            <UsersSection
                key="investors"
                title="Инвесторы"
            >
                {investors.map(investor => (
                    <InvestorUserAccountsGroupComponent
                        key={investor.id}
                        id={investor.id}
                        activeAccountId={currentAccountId}
                        name={investor.name}
                        amount={investor.amount}
                        amountType={isInvestorHasAnyActivePortfolio(investor) ? 'default' : 'danger'}
                        accountsHint="Выберите портфель"
                        accounts={investor.portfolios}
                        UserAccountComponent={InvestorUserAccount}
                        onAccountClick={didInvestorAccountClicked}
                    />
                ))}
            </UsersSection>
        ),
        [ClientType.Borrower]: borrowers && borrowers.length > 0 && (
            <UsersSection
                key="borrowers"
                title="Заёмщики"
            >
                {borrowers.map(borrower => (
                    <UserAccountsGroup
                        key={borrower.id}
                        id={borrower.id}
                        activeAccountId={currentAccountId}
                        name={borrower.name}
                        onAccountClick={didBorrowerAccountClicked}
                    />
                ))}
            </UsersSection>
        ),
        [ClientType.Partner]: partners && partners.length > 0 && (
            <UsersSection
                key="partners"
                title="Партнеры"
            >
                {partners.map(partner => (
                    <UserAccountsGroup
                        key={partner.id}
                        id={partner.id}
                        activeAccountId={currentAccountId}
                        name={partner.name}
                        onAccountClick={didPartnerAccountClicked}
                    />
                ))}
            </UsersSection>
        ),
    };

    const userGroupsOrder = reorderUserGroups(clientType);

    return (
        <UserSidePanel
            isOpen={isOpen}
            header={header}
            footer={(
                <DropdownActions
                    button={(
                        <Button
                            type="secondary"
                            noSpacing
                            block
                            icon={iconPlusPrimary}
                            iconPosition="right"
                            text="Добавить счет"
                        />
                    )}
                    hover
                    placement="top"
                >
                    {!hasIndividualInvestorUser && (
                        <DropdownActions.Link external href={getAccountCreateEndpoint(ClientType.Investor, { type: InvestorUserType.Individual })}>
                            Инвестора физ. лица
                        </DropdownActions.Link>
                    )}
                    <DropdownActions.Link external href={getAccountCreateEndpoint(ClientType.Investor, { type: InvestorUserType.Company })}>
                        Инвестора юр. лица
                    </DropdownActions.Link>
                    <DropdownActions.Link external href={getAccountCreateEndpoint(ClientType.Borrower)}>
                        Заемщика
                    </DropdownActions.Link>
                    <DropdownActions.Link external href={getAccountCreateEndpoint(ClientType.Partner)}>
                        Партнера
                    </DropdownActions.Link>
                </DropdownActions>
            )}
            onClose={close}
            onLogout={didLogoutClicked}
        >
            {userGroupsOrder.map(groupClientType => userGroupsByClientType[groupClientType])}
            {isFetching && (
                <LoaderBlock />
            )}
        </UserSidePanel>
    );
}