/**
 * Файл глобальной конфигурации клиентского приложения.
 */

import NetworkManager from '@frontend/jetlend-core/src/services/NetworkManager';
import { DefaultComponents } from '@frontend/jetlend-web-ui/src/common/DefaultComponents';
import Link from 'next/link';

// Настройка глобального сетевого поведения на клиенте
NetworkManager.config.apiEndpoint = process.env.NEXT_PUBLIC_JETLEND_ENDPOINT;
NetworkManager.config.domainUrl = process.env.NEXT_PUBLIC_JETLEND_ENDPOINT;

// Настройка некоторых дефолтных компонентов внутри общей библиотеки
// Используем NextJS Link вместо обычного <a> для активации клиентской навигации по страницам сайта
DefaultComponents.Link = Link as any;
