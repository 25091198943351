import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { cnx } from '@ui/utils/classNameUtils';
import { DefaultComponents } from '@ui/common/DefaultComponents';
import styles from './dropdownActions.module.scss';

interface IProps {
    children: ReactNode;

    href: string;
    external?: boolean;
    className?: string;
    noFollow?: boolean;
}

const DropdownActionsLink = (props: IProps) => {
    const {
        children,
        href,
        external = false,
        noFollow = false,
        className,
    } = props;

    const buttonClassName = cnx([
        styles['button'],
        className,
    ]);

    function getRelValue() {
        if (external) {
            return 'noopener noreferrer nofollow';
        }

        if (noFollow) {
            return 'nofollow';
        }

        return undefined;
    }

    const isReactRouterLink = !external && DefaultComponents.Link === Link;

    // eslint-disable-next-line no-nested-ternary
    const LinkComponent = isReactRouterLink
        ? Link
        : external
            ? 'a'
            : DefaultComponents.Link;

    return (
        // Нужные свойства безопасности устанавливаются через функцию, eslint их не видит, поэтому используем атрибут
        // eslint-disable-next-line react/jsx-no-target-blank
        <LinkComponent
            // Синтаксис React Router немного отличается от остальных
            href={isReactRouterLink ? undefined : href}
            to={isReactRouterLink ? href : undefined}
            rel={getRelValue()}
            target={external ? '_blank' : undefined}
            className={buttonClassName}
        >
            {children}
        </LinkComponent>
    );
};

export default DropdownActionsLink;
