'use client';

import { useEffect } from 'react';
import {
    usePathname,
    useSearchParams,
} from 'next/navigation';
import { safe } from '@frontend/jetlend-core/src/utils/globalUtils';
import { IProps } from './YandexMetrika';

/**
 * Компонент для отслеживания переходов между страницами.
 */
export default function YandexMetrikaClient({ id }: IProps) {
    const pathName = usePathname();
    const searchParams = useSearchParams();

    useEffect(() => {
        if (typeof window === 'undefined') {
            return;
        }

        safe(() => {
            (window as any).ym(id, 'hit', window.location.href);
        });
    }, [ id, pathName, searchParams ]);

    return null;
}