import React, {
    PropsWithChildren,
    useEffect,
    useLayoutEffect,
    useRef,
} from 'react';
import Tippy, { TippyProps } from '@tippyjs/react';
import { Instance } from 'tippy.js';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light.css';
import 'tippy.js/animations/perspective.css';

import { useContainerWrapper } from '@ui/hooks/useContainerWrapper';

export interface IProps extends PropsWithChildren {
    content: any;
    isOpen?: boolean;
    onClose?: () => void;
    onContextMenu?: (e: React.MouseEvent<HTMLDivElement>) => void;
    className?: TippyProps['className'];
    /**
     * Дополнительный класс стилей, который будет применен вокруг вложенного элемента.
     */
    containerClassName?: string;
    withoutArrow?: boolean;
    placement?: TippyProps['placement'];
    maxWidth?: TippyProps['maxWidth'];
    hover?: boolean;
    disableClickWithHover?: boolean;
    strategy?: 'default' | 'overflow';
    detached?: boolean;
    useContextMenuBehavior?: boolean;
    zIndex?: number;
}

const PopupWindow: React.FC<IProps> = props => {

    const containerRef = useRef<HTMLDivElement>();
    const tippyRef = useRef<Element>();
    const tippyInstanceRef = useRef<Instance>();

    // eslint-disable-next-line no-nested-ternary
    const trigger = props.hover
        ? props.disableClickWithHover
            ? 'mouseenter focus touch'
            : 'mouseenter focus click touch'
        : 'manual';

    useContainerWrapper(containerRef, [ props.children ]);

    const commonProps: TippyProps = {
        className: props.className,
        arrow: !props.withoutArrow,
        animation: 'perspective',
        zIndex: props.zIndex ?? 999,
        theme: 'light',
        interactive: true,
        onClickOutside: props.onClose,
        trigger: props.useContextMenuBehavior ? 'contextmenu' : trigger,
        placement: props.placement,
        maxWidth: props.maxWidth,
        popperOptions: props.strategy === 'overflow'
            ? {
                strategy: 'fixed',
                modifiers: [
                    {
                        name: 'preventOverflow',
                        options: {
                            altAxis: true,
                            tether: false,
                        },
                    },
                ],
            }
            : {},
        appendTo: props.detached
            ? document.body
            : undefined,
    };

    useLayoutEffect(() => {
        if (!tippyRef.current) {
            return;
        }

        const instance = (tippyRef.current as any)._tippy as Instance;
        tippyInstanceRef.current = instance;

        if (props.isOpen) {
            instance.show();
        }
    }, [ tippyRef ]);

    useEffect(() => {
        if (!tippyInstanceRef.current) {
            return;
        }

        if (props.isOpen) {
            tippyInstanceRef.current.show();
        } else {
            tippyInstanceRef.current.hide();
        }
    }, [ tippyInstanceRef, props.isOpen ]);

    return (
        <Tippy ref={tippyRef} content={props.content} {...commonProps}>
            <div ref={containerRef} onContextMenu={props.onContextMenu} className={props.containerClassName}>
                {props.children}
            </div>
        </Tippy>
    );
};

export default PopupWindow;