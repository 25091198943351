'use client';

import { connectFunctionalComponent } from '@frontend/jetlend-core/src/ducks/connect';
import Modal from '@frontend/jetlend-web-ui/src/ui/modals/Modal';
import React, { useCallback } from 'react';
import { IFormProps } from '@frontend/jetlend-core/src/models/form';
import LoaderBlock from '@frontend/jetlend-web-ui/src/ui/loaders/LoaderBlock';
import Link from 'next/link';
import {
    changePhoneNumber,
    commonRegistrationModalHandler,
    commonRegistrationStateHandler,
} from '@app/ducks/common/registration';
import {
    IRegistrationFormValues,
    IRegistrationState,
} from '@app/models/common/registration';
import StepPhoneForm from './steps/StepPhoneForm/StepPhoneForm';
import StepSmsForm from './steps/StepSmsForm/StepSmsForm';
import styles from './registrationModal.module.scss';
import StepEmailForm from './steps/StepEmailForm/StepEmailForm';
import InvestorAppBadges from '@app/components/server/common/InvestorAppBadges/InvestorAppBadges';
import {
    ClientType,
    CommonStage,
} from '@app/models/common/common';

type Props = IFormProps<IRegistrationFormValues> & {
    state: IRegistrationState;
    isOpen?: boolean;
    closeSelf: () => void;
    onPhoneChange: typeof changePhoneNumber;
}

function getModalTitle(state: IRegistrationState): string {
    switch (state.clientType) {
    case ClientType.Investor:
        return 'Регистрация инвестора';
    case ClientType.Borrower:
        return 'Регистрация заемщика';
    case ClientType.Partner:
        return 'Регистрация партнера';
    default:
        return '';
    }
}

const RegistrationModal: React.FC<Props> = props => {
    const {
        state,
        onPhoneChange,
    } = props;

    const isOpen = props.isOpen === true;

    const didClosed = useCallback(() => props.closeSelf(), []);

    const didChangePhoneNumberClicked = useCallback(() => {
        onPhoneChange && onPhoneChange();
    }, [onPhoneChange]);

    if (!state) {
        return null;
    }

    const modalTitle = getModalTitle(state);

    return (
        <Modal
            title
            noBorder
            size="fullscreen"
            closable
            isOpen={isOpen}
            onClosed={didClosed}
            contentClassName={styles['wrapper']}
            testId="registration-modal"
        >
            <div className={styles['container']}>
                <h1 className={styles['title']} data-testid="registration-modal__title">
                    {modalTitle}
                </h1>

                <div className={styles['content']}>
                    {state.stage === CommonStage.Phone &&
                        <StepPhoneForm state={state} />
                    }

                    {state.stage === CommonStage.Sms &&
                        <StepSmsForm />
                    }

                    {state.stage === CommonStage.Email &&
                        <StepEmailForm state={state} />
                    }

                    {state.stage === CommonStage.Loading && (
                        <div>
                            <LoaderBlock size="xlarge" spinnerType="three-sides-circle" />

                            <div className="h-ta-center text-muted">
                                {state?.loadingTitle}
                            </div>
                        </div>
                    )}
                </div>

                <div className={styles['footer']}>
                    {state.stage === CommonStage.Sms && (
                        <button
                            type="button"
                            className={styles['change-phone']}
                            onClick={didChangePhoneNumberClicked}
                        >
                            Изменить номер
                        </button>
                    )}
                    {[ CommonStage.Phone, CommonStage.Sms ].includes(state.stage) && (
                        <p className={styles['policies']} data-testid="registration-modal__policies">
                            Ввод номера телефона подтверждает ваше согласие с <Link target="_blank" href="/doc-view/rules" rel="nofollow">правилами</Link> платформы и <Link href="/doc-view/privacy" target="_blank" rel="nofollow">политикой обработки персональных данных</Link>
                        </p>
                    )}

                    {state.clientType === ClientType.Investor && (
                        <div className={styles['app-badges']} data-testid="registration-modal__app-badges">
                            <InvestorAppBadges dark />
                        </div>
                    )}
                </div>
            </div>
        </Modal>
    );
};

export default connectFunctionalComponent(RegistrationModal, {
    selectors: {
        state: commonRegistrationStateHandler.selector,
        isOpen: commonRegistrationModalHandler.isOpen,
    },
    dispatch: {
        closeSelf: commonRegistrationModalHandler.close,
        onPhoneChange: changePhoneNumber,
    },
}) as React.FC;
