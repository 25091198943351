import React, { useCallback } from 'react';
import menuIcon from '@frontend/jetlend-assets/icons/header-menu-arrow.svg';
import menuMobileIcon from '@frontend/jetlend-assets/icons/header-menu-arrow--mobile.svg';
import { getAssetSrc } from '@ui/utils/getAssetSrc';
import {
    isMobile,
    useWindowSize,
} from '@ui/utils/responsive';
import PopupWindow from '@ui/ui/PopupWindow/PopupWindow';
import { buildClassNames } from '../../utils/classNameUtils';

import styles from './responsiveMenu.module.scss';
import {
    useResponsiveMenuButtonContext,
    useResponsiveMenuContext,
} from './ResponsiveMenu.context';
import ResponsiveMenuButton, { IProps as ResponsiveMenuButtonProps } from './ResponsiveMenu.Button';
import MenuButtonWrapper from './MenuButtonWrapper';

export interface IProps extends ResponsiveMenuButtonProps, React.PropsWithChildren {
}

/**
 * Компонент пункта меню с выпадающим списком.
 * В качестве дочерних элементов необходимо передавать {@link ResponsiveMenuButton}.
 *
 * Должно применяться совместно с компонентом {@link ResponsiveMenu}.
 */
export default function ResponsiveMenuDropdownAction({
    title,
    href,
    rel,
    active,
    children,
}: IProps) {
    const { width } = useWindowSize();
    const isMobileScreen = isMobile(width);

    const icon = isMobileScreen ? menuMobileIcon : menuIcon;
    const isDropdownListRequired = (children && React.Children.count(children) > 0) || false;

    const menuContext = useResponsiveMenuContext();
    const buttonContext = useResponsiveMenuButtonContext();
    const isMenuExpanded = buttonContext?.placement === 'menu';
    const isMainMenu = buttonContext?.placement === 'main';

    const isHidden = isMainMenu && buttonContext?.hidden;
    const linkClassName = buildClassNames(styles, [
        isHidden && 'button--hidden',
        'dropdown',
        active && 'dropdown--active',
    ]);

    const menuIconClassName = buildClassNames(styles, [
        'dropdown-icon-wrapper',
        !isMainMenu && 'dropdown-icon-wrapper--right',
    ]);

    const didDropdownArrowClicked = useCallback((e) => {
        e.preventDefault();
        e.stopPropagation();

        if (menuContext) {
            menuContext.slideTo({
                title,
                children,
            });
        }
    }, [ menuContext, title, children ]);

    return (
        <PopupWindow
            placement={isMenuExpanded ? 'right-end' : 'bottom'}
            detached={isMenuExpanded}
            zIndex={1007}
            hover={!isMobileScreen && isDropdownListRequired && !isHidden}
            disableClickWithHover
            withoutArrow={false}
            content={(
                <div className={styles['popup-container']}>
                    {React.Children.map(children, (child, index) => (
                        <MenuButtonWrapper
                            key={index}
                            index={index}
                            hidden={false}
                            placement="sub-menu"
                        >
                            {child}
                        </MenuButtonWrapper>
                    ))}
                </div>
            )}
        >
            <ResponsiveMenuButton
                className={linkClassName}
                active={active}
                href={href}
                rel={rel}
                title={(
                    <>
                        {title}
                        {isDropdownListRequired && (
                            <div
                                className={menuIconClassName}
                                onClick={didDropdownArrowClicked}
                            >
                                <img src={getAssetSrc(icon)} alt="" />
                            </div>
                        )}
                    </>
                )}
            />
        </PopupWindow>
    );
};