'use client';

import React, {
    useRef,
    useState,
    useCallback,
    useLayoutEffect,
    useMemo,
} from 'react';
import capitalize from '@frontend/jetlend-core/src/formatters/capitalize';
import backIcon from '@frontend/jetlend-assets/icons/icon-sub-menu-back.svg';
import { useResizeObserver } from '@ui/hooks/useResizeObserver';
import { useScroll } from '@ui/hooks/useScroll';
import { useScrollWidth } from '@ui/hooks/useScrollWidth';
import { getAssetSrc } from '@ui/utils/getAssetSrc';
import ResponsiveMenuButton from './ResponsiveMenu.Button';
import styles from './responsiveMenu.module.scss';
import HeaderBurgerButton from '../../layout/HeaderBurgerButton';
import PopupWindow from '../../ui/PopupWindow/PopupWindow';
import { buildClassNames } from '../../utils/classNameUtils';
import {
    isMobile,
    useWindowSize,
} from '../../utils/responsive';
import Modal from '../../ui/modals/Modal';
import MenuButtonWrapper from './MenuButtonWrapper';
import {
    IResponsiveMenuContext,
    ISlideProps,
    ResponsiveMenuContext,
} from './ResponsiveMenu.context';

export interface IProps extends React.PropsWithChildren {
    /**
     * Логотип отображаемый в левом углу блока меню.
     */
    logo?: JSX.Element|React.ReactNode;
    /**
     * Маленькая версия логотипа, использующиеся в мобильной версии меню.
     */
    smallLogo?: JSX.Element|React.ReactNode;
    /**
     * Дополнительные действия, отображаемые в правом углу блока меню.
     */
    actions?: JSX.Element|React.ReactNode;
    /**
     * Закреплено ли меню вверху экрана.
     */
    fixed?: boolean;
}

/**
 * Компонент адаптивного меню для сайта.
 * Все пункты меню перечисляются в дочерних элементах с помощью компонентов {@link ResponsiveMenuButton} или {@link ResponsiveMenuDropdownAction}.
 *
 * Если места для меню недостаточно, то элементы скрываются выпадающим списком.
 *
 * Для мобильного отображения предусмотрено полноэкранное меню.
 */
function ResponsiveMenu({
    fixed,
    actions,
    logo,
    smallLogo,
    children,
}: IProps) {
    const isScrolled = useScroll(fixed);
    useScrollWidth();

    const mainContainerRef = useRef<HTMLDivElement>(null);
    const menuNavigationRef = useRef<HTMLDivElement>(null);

    const [ collapsed, setCollapsed ] = useState(false);
    const [ hiddenAtIndex, setHiddenAtIndex ] = useState(-1);
    const [ menuExpanded, setMenuExpanded ] = useState(false);

    const { width: screenWidth } = useWindowSize();
    const isMobileScreen = isMobile(screenWidth);

    const [ buttonWidthMap, setButtonWidthMap ] = useState<Record<number, number>>({});

    const [ mainContainerWidth ] = useResizeObserver(mainContainerRef);

    const didButtonWidthChanged = useCallback((index: number, buttonWidth: number) => {
        setButtonWidthMap(prevState => ({
            ...prevState,
            [index]: buttonWidth,
        }));
    }, []);

    useLayoutEffect(() => {
        // Reduce available width by 60 by able place menu button
        const minAvailableMenuWidth = mainContainerWidth - 60;

        let willCollapse = false;
        let renderedWidth = 0;
        Object.entries(buttonWidthMap).forEach(([ buttonIndexKey, buttonWidth ]) => {
            const buttonIndex = Number(buttonIndexKey);

            const shouldButtonHidden = buttonWidth > 0 && renderedWidth + buttonWidth >= minAvailableMenuWidth;
            if (shouldButtonHidden && !willCollapse) {
                setHiddenAtIndex(buttonIndex);
                willCollapse = true;
            }

            renderedWidth += buttonWidth;
        });

        setCollapsed(willCollapse);
        if (!willCollapse) {
            setMenuExpanded(false);
            setHiddenAtIndex(-1);
        }
    }, [ mainContainerWidth, buttonWidthMap ]);

    const didMenuToggle = useCallback(() => setMenuExpanded(expanded => !expanded), []);
    const didMenuClosed = useCallback(() => setMenuExpanded(false), []);

    const [ slideProps, setSlideProps ] = useState<ISlideProps>();
    const [ slideIsActive, setSlideIsActive ] = useState(false);

    const didBackClicked = useCallback(() => {
        setSlideIsActive(false);
    }, []);

    const context = useMemo<IResponsiveMenuContext>(() => ({
        slideTo(props) {
            setSlideProps(props);
            setSlideIsActive(true);
        },
        backToMenu: didBackClicked,
        closeBurgerMenu: () => setMenuExpanded(false),
    }), [ didBackClicked ]);

    const containerClassName = buildClassNames(styles, [
        'container',
        fixed && 'container--fixed',
        isScrolled && 'container--scrolled',
    ]);

    const mainClassName = buildClassNames(styles, [
        'main',
        collapsed && 'main--collapsed',
    ]);

    // TODO: Разделить реализацию десктоп и мобильной версии на два разных компонента и использовать их здесь
    return (
        <ResponsiveMenuContext.Provider value={context}>
            <header className={containerClassName}>
                <div className={styles['logo']}>
                    <div className={styles['logo--small']}>
                        {smallLogo ?? logo}
                    </div>
                    <div className={styles['logo--normal']}>
                        {logo}
                    </div>
                </div>
                <div ref={mainContainerRef} className={mainClassName}>
                    <div className={styles['main__navigation']}>
                        {React.Children.map(children, (child, index) => (
                            <MenuButtonWrapper
                                key={index}
                                index={index}
                                hidden={hiddenAtIndex >= 0 && index >= hiddenAtIndex}
                                placement="main"
                                onWidthChange={didButtonWidthChanged}
                            >
                                {child}
                            </MenuButtonWrapper>
                        ))}
                    </div>
                    {collapsed && !isMobileScreen && (
                        <div className={styles['main__menu']}>
                            <PopupWindow
                                isOpen={menuExpanded}
                                onClose={didMenuClosed}
                                placement="bottom"
                                content={(
                                    <div ref={menuNavigationRef} className={styles['popup-container']}>
                                        {React.Children.map(children, (child, index) => index >= hiddenAtIndex && (
                                            <MenuButtonWrapper
                                                key={index}
                                                index={index}
                                                hidden={false}
                                                placement="menu"
                                            >
                                                {child}
                                            </MenuButtonWrapper>
                                        ))}
                                    </div>
                                )}
                            >
                                <HeaderBurgerButton
                                    inverted
                                    noMargin
                                    opened={menuExpanded}
                                    onClick={didMenuToggle}
                                />
                            </PopupWindow>
                        </div>
                    )}
                    <div className={buildClassNames(styles, ['main__menu', 'main__menu--mobile'])}>
                        <HeaderBurgerButton inverted opened={menuExpanded} onClick={didMenuToggle} />
                    </div>
                </div>
                {actions && (
                    <div className={styles['actions']}>
                        {actions}
                    </div>
                )}
            </header>
            {fixed &&
                <div className={styles['container-placeholder']} />
            }
            {isMobileScreen && (
                <Modal
                    headerClassName={styles['modal__header']}
                    contentClassName={styles['modal__content']}
                    title={(
                        <div>
                            <div className={styles['logo']}>
                                {logo}
                            </div>
                        </div>
                    )}
                    noBorder
                    isOpen={menuExpanded}
                    closable
                    onClosed={didMenuClosed}
                >
                    <div className={styles['modal__slides']}>
                        <div
                            className={buildClassNames(styles, [
                                'modal-container',
                                'modal-container--primary',
                                slideIsActive && 'modal-container--inactive',
                            ])}
                        >
                            {children}
                        </div>
                        <div
                            className={buildClassNames(styles, [
                                'modal-container',
                                'modal-container--slide',
                                slideIsActive && 'modal-container--active',
                            ])}
                        >
                            <div className={styles['sub-menu-container']}>
                                <div className={styles['sub-menu-title']} onClick={didBackClicked}>
                                    <div className={styles['sub-menu-title__icon']}>
                                        <img src={getAssetSrc(backIcon)} alt="" />
                                    </div>
                                    <div>
                                        {typeof slideProps?.title === 'string'
                                            ? capitalize(slideProps?.title)
                                            : slideProps?.title
                                        }
                                    </div>
                                </div>
                                <div className={styles['sub-menu-links']}>
                                    {slideProps?.children}
                                </div>
                            </div>
                        </div>
                    </div>
                    {actions && (
                        <>
                            <hr className={styles['modal__actions-divider']} />
                            <div className={styles['modal__actions']}>
                                {actions}
                            </div>
                        </>
                    )}
                </Modal>
            )}
        </ResponsiveMenuContext.Provider>
    );
};

export {
    ResponsiveMenuButton,
};

export default ResponsiveMenu;