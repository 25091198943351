import { composeWithDevTools } from 'redux-devtools-extension';
import { all } from 'redux-saga/effects';
import {
    applyMiddleware,
    combineReducers,
    createStore,
} from 'redux';
import createSagaMiddleware from 'redux-saga';
import {
    getReducers,
    getSagas,
} from '@frontend/jetlend-core/src/ducks/core';
import { UnexpectedSagaError } from '@frontend/jetlend-core/src/ducks/utils';
import ducks from '@app/ducks/index';
import '../config';

const sagas = getSagas(ducks);

function* rootSaga(...args) {
    yield all(sagas.map((saga: any) => saga(...args)));
}


function configureDevStore(initialState = {}) {
    const sagaMiddleware = createSagaMiddleware({
        onError(error, errorInfo) {
            console.error(new UnexpectedSagaError(error.message, error, errorInfo.sagaStack));
        },
    });
    const middlewares = [
        sagaMiddleware,
    ];

    const appReducer = combineReducers(getReducers(ducks));

    const rootReducer = (state, action) => {
        if (action.type === 'jl/GLOBAL/RESET_STATE') {
            return appReducer(initialState, action);
        }

        return appReducer(state, action);
    };

    const composeEnhancers = composeWithDevTools({});

    const store = createStore<any, any, any, any>(
        rootReducer,
        initialState,
        composeEnhancers(applyMiddleware(...middlewares))
    );

    sagaMiddleware.run(rootSaga);
    // Hack & Trick
    store.runSaga = sagaMiddleware.run;

    return store;
}

function configureProdStore(initialState = {}) {
    const sagaMiddleware = createSagaMiddleware({
        onError(error, errorInfo) {
            console.error(new UnexpectedSagaError(error.message, error, errorInfo.sagaStack));
        },
    });
    const middlewares = [
        sagaMiddleware,
    ];

    const store = createStore<any, any, any, any>(
        combineReducers(getReducers(ducks)),
        initialState,
        applyMiddleware(...middlewares)
    );

    sagaMiddleware.run(rootSaga);
    // Hack & Trick
    store.runSaga = sagaMiddleware.run;

    return store;
}

const isNextRuntime = Boolean(process.env.NEXT_RUNTIME);

function makeStore() {
    return process.env.NODE_ENV === 'production' ? configureProdStore() : configureDevStore();
}

export const store = makeStore();
